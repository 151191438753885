<template>
  <div style="background-color: #e5f6ff">
    <van-nav-bar left-arrow @click-left="onClickLeft">
      <template #title>
        <span style="font-weight: 600; color: #0af">星球会员</span>
      </template>
    </van-nav-bar>

    <div class="module-line">
      <span class="arrow left-arrow"></span>
      <span class="text">会员特权对比</span>
      <span class="arrow right-arrow"></span>
    </div>

    <div class="module-privilege">
      <ul class="privilege-ul name-ul">
        <li class="privilege-item first-item">
          <p class="title">
            <span>星球服务</span>
          </p>
        </li>
        <li class="privilege-item" style="background: #00aaff1a">

            📈 Go线路图
          </li>
        <li class="privilege-item">
          📘 Go博文
        </li>
        <li class="privilege-item" style="background: #00aaff1a">
          📦 Go仓库
        </li>
        <li class="privilege-item">
          📆 Go月刊
        </li>
        <li class="privilege-item" style="background: #00aaff1a">
          🔍 内推互助
        </li>
        <li class="privilege-item">
          📕 推荐图书
        </li>
        <li class="privilege-item" style="background: #00aaff1a">
          📚 Go宝典
        </li>
        <li class="privilege-item">
          👨‍🏫 Go小课
        </li>
        <li class="privilege-item" style="background: #00aaff1a">
          🚀 面试宝典
        </li>
        <li class="privilege-item">
          📺 Go视界
        </li>
        <li class="privilege-item" style="background: #00aaff1a">
          📥 资源下载
        </li>
        <li class="privilege-item">
          ⭐ 企业题库
        </li>
        <li class="privilege-item" style="background: #00aaff1a">
          👪 推广返佣
        </li>
        <li class="privilege-item">
          💯 Go小考
        </li>
        <li class="privilege-item" style="background: #00aaff1a">
          💎 优质课程
        </li>
        <li class="privilege-item">
          ⚔️ Go实战
        </li>
        <li class="privilege-item" style="background: #00aaff1a">
          🤝 求职助力
        </li>
        <li class="privilege-item">
          🕵️ 帮找资源
        </li>
        <li class="privilege-item" style="background: #00aaff1a">
          🎨 Go周边
        </li>
      </ul>
      <ul class="privilege-ul svip-ul active recommend">
        <li class="privilege-item first-item">
          <div class="svip-type" style="margin-top: 8px; height: 42px">
            <p class="vip-type-icon">
              <span class="vip-icon svip-middle icon-size-middle"></span>
            </p>
            <span class="vip-type-title">VIP</span>
          </div>
          <div class="buy-btn-box">
            <span>¥</span>
            <span style="font-size: 20px; font-weight: 490">269</span>
            <!-- <span style="text-decoration: line-through">¥ 299</span> -->
            <span>/年</span>
          </div>
        </li>
        <li class="privilege-item" style="background: #fbf4e4">
          <span class="icon right"></span>
        </li>
        <li class="privilege-item"><span class="icon right"></span></li>
        <li class="privilege-item" style="background: #fbf4e4">
          <span class="icon right"></span>
        </li>
        <li class="privilege-item"><span class="icon right"></span></li>
        <li class="privilege-item" style="background: #fbf4e4">
          <span class="icon right"></span>
        </li>
        <li class="privilege-item"><span class="icon right"></span></li>
        <li class="privilege-item" style="background: #fbf4e4">
          <span class="icon right"></span>
        </li>
        <li class="privilege-item"><span class="icon right"></span></li>
        <li class="privilege-item" style="background: #fbf4e4">
          <span class="icon right"></span>
        </li>
        <li class="privilege-item"><span class="icon right"></span></li>
        <li class="privilege-item" style="background: #fbf4e4">
          <span class="icon right"></span>
        </li>
        <li class="privilege-item"><span class="icon right"></span></li>
        <li class="privilege-item" style="background: #fbf4e4">
          <span class="icon right"></span>
        </li>
        <li class="privilege-item"><span class="icon right"></span></li>
        <li class="privilege-item" style="background: #fbf4e4">试看/返利</li>
        <li class="privilege-item">折扣权益</li>
        <li class="privilege-item" style="background: #fbf4e4">折扣权益</li>
        <li class="privilege-item">免费帮找</li>
        <li class="privilege-item" style="background: #fbf4e4">送鼠标垫</li>
      </ul>
      <ul class="privilege-ul vip-ul">
        <li class="privilege-item first-item">
          <div class="vip-type" style="height: 30px">
            <p class="vip-type-icon">
              <span class="vip-icon vip-middle icon-size-middle"></span>
            </p>
            <span class="vip-type-title">体验VIP</span>
          </div>
          <div class="buy-btn-box">
            <p style="color: chocolate">新用户享7天</p>
          </div>
        </li>
        <li class="privilege-item" style="background: #00aaff1a">
          <span class="icon right"></span>
        </li>
        <li class="privilege-item"><span class="icon right"></span></li>
        <li class="privilege-item" style="background: #00aaff1a">
          <span class="icon right"></span>
        </li>
        <li class="privilege-item"><span class="icon right"></span></li>
        <li class="privilege-item" style="background: #00aaff1a">
          <span class="icon right"></span>
        </li>
        <li class="privilege-item"><span class="icon right"></span></li>
        <li class="privilege-item" style="background: #00aaff1a">
          <span class="icon right"></span>
        </li>
        <li class="privilege-item"><span class="icon right"></span></li>
        <li class="privilege-item" style="background: #00aaff1a">
          <span class="icon right"></span>
        </li>
        <li class="privilege-item"><span class="icon right"></span></li>
        <li class="privilege-item" style="background: #00aaff1a">
          <span class="icon error"></span>
        </li>
        <li class="privilege-item"><span class="icon error"></span></li>
        <li class="privilege-item" style="background: #00aaff1a">
          <span class="icon error"></span>
        </li>
        <li class="privilege-item"><span class="icon error"></span></li>
        <li class="privilege-item" style="background: #00aaff1a">
          <span class="icon error"></span>
        </li>
        <li class="privilege-item">无折扣</li>
        <li class="privilege-item" style="background: #00aaff1a">无折扣</li>
        <li class="privilege-item">付费帮找</li>
        <li class="privilege-item" style="background: #00aaff1a">
          <span>无赠送</span>
        </li>
      </ul>
      <ul class="privilege-ul no-vip-ul">
        <li class="privilege-item first-item">
          <div class="no-vip-type">
            <p class="vip-type-icon">
              <span class="vip-icon no-vip-middle icon-size-middle"></span>
            </p>
            <span class="vip-type-title"> 非VIP</span>
          </div>
        </li>
        <li class="privilege-item" style="background: #00aaff1a">
          <span class="icon right"></span>
        </li>
        <li class="privilege-item"><span class="icon right"></span></li>
        <li class="privilege-item" style="background: #00aaff1a">
          <span class="icon right"></span>
        </li>
        <li class="privilege-item"><span class="icon right"></span></li>
        <li class="privilege-item" style="background: #00aaff1a">
          <span class="icon right"></span>
        </li>
        <li class="privilege-item"><span class="icon right"></span></li>
        <li class="privilege-item" style="background: #00aaff1a">
          <span class="icon error"></span>
        </li>
        <li class="privilege-item"><span class="icon error"></span></li>
        <li class="privilege-item" style="background: #00aaff1a">
          <span class="icon error"></span>
        </li>
        <li class="privilege-item"><span class="icon error"></span></li>
        <li class="privilege-item" style="background: #00aaff1a">
          <span class="icon error"></span>
        </li>
        <li class="privilege-item"><span class="icon error"></span></li>
        <li class="privilege-item" style="background: #00aaff1a">
          <span class="icon error"></span>
        </li>
        <li class="privilege-item"><span class="icon error"></span></li>
        <li class="privilege-item" style="background: #00aaff1a">
          <span class="icon error"></span>
        </li>
        <li class="privilege-item">无折扣</li>
        <li class="privilege-item" style="background: #00aaff1a">无折扣</li>
        <li class="privilege-item">付费帮找</li>
        <li class="privilege-item" style="background: #00aaff1a">
          <span>无赠送</span>
        </li>
      </ul>
    </div>

    <van-dialog
      v-model="show"
      title="🚀 购买会员激活码"
      cancelButtonText="下次一定"
      confirmButtonText="激活会员"
      show-cancel-button
      :beforeClose="beforeCloseFn"
    >
      <div style="text-align: center; margin-top: 10px; margin-bottom: 10px">
        <van-image
          width="200"
          height="200"
          src="https://image-1302243118.cos.ap-beijing.myqcloud.com/public/img/wd/vip2.png"
        />
        <van-field
          required
          maxlength="12"
          v-model="coupon"
          label="会员激活码："
          placeholder="请输入会员激活码"
        />
      </div>
    </van-dialog>

    <van-submit-bar
      label="实付："
      tip-icon="info-o"
      tip="部分权益属于电脑端，可前往星球PC端进行了解。"
      :price="26900"
      button-color="#edd3a7"
      button-text="立即购买"
      @submit="onSubmit"
    >
      <van-checkbox v-model="checked">年度会员</van-checkbox>
    </van-submit-bar>
  </div>
</template>

<script>
import {
  NavBar,
  Toast,
  Field,
  Image as VanImage,
  Dialog,
  Checkbox,
  SubmitBar,
  Row,
  Col,
  Icon,
  Cell,
  CellGroup,
} from "vant";
const axios = require("axios");
export default {
  components: {
    [NavBar.name]: NavBar,
    [Toast.name]: Toast,
    [Field.name]: Field,
    VanImage,
    [Dialog.Component.name]: Dialog.Component,
    [Checkbox.name]: Checkbox,
    [SubmitBar.name]: SubmitBar,
    [Row.name]: Row,
    [Col.name]: Col,
    [Icon.name]: Icon,
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup,
  },
  data() {
    return {
      checked: true,
      show: false,
      coupon: "",
    };
  },
  methods: {
    onClickLeft() {
      this.$router.push("user");
    },
    onSubmit() {
      this.show = true;
    },
    beforeCloseFn(action, done) {
      // 执行一些校验或处理逻辑
      if (action === "confirm") {
        // 如果点击了确认按钮，则继续关闭对话框
        if (this.coupon === "") {
          Toast.fail("激活码不能为空！");
          setTimeout(done, 1000);
          return;
        }

        this.orderClick();
        setTimeout(done, 1000);
      } else {
        // 如果点击了取消按钮，则阻止对话框关闭

        done();
      }
    },
    orderClick() {
      const params = new URLSearchParams();
      params.append("vipType", 2);
      params.append("coupon", this.coupon);
      axios
        .post("https://api.golangroadmap.com/activateVip", params)
        .then((response) => {
          if (response.status == "200" && response.data.code == "217") {
            //此处获取用户vToken放入到session中
            window.sessionStorage.setItem(
              "userVo",
              window.JSON.stringify(response.data.data)
            );
            window.sessionStorage.setItem("vToken", response.data.data.vToken);
            Toast.success("激活成功");
          } else {
            Toast.fail(response.data.msg);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>

<style >
.module-line {
  width: 100%;
  text-align: center;
  margin-top: 20px;
  margin-bottom: 30px;
}
.module-line span {
  display: inline-block;
  *display: inline;
  *zoom: 1;
}
.module-line .text {
  font-size: 16px;
  color: #4c4c4c;
  margin: 0 10px;
}
.module-line .arrow {
  width: 84px;
  height: 16px;
}
.module-line .left-arrow {
  background: url(./images/vip/leftArrow.png);
}
.module-line .right-arrow {
  background: url(./images/vip/rightArrow.png);
}
.module-privilege {
  width: 100%;
  height: 1040px;

  position: relative;
}
.module-privilege .privilege-ul {
  float: left;
  border: 1px solid #d3d1d0;
  background: #fff;
  width: 25%;
  position: absolute;
  padding-left: 0.1rem;
}
.module-privilege .privilege-ul.name-ul {
  list-style: none;
  left: 0;
}
.module-privilege .privilege-ul.svip-ul {
  list-style: none;
  left: 275px;
  color: #ca963b;
  -moz-box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.08);
  box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.08);
}
.module-privilege .privilege-ul.vip-ul {
  list-style: none;
  left: 50%;
  border-right: 0;
}
.module-privilege .privilege-ul.no-vip-ul {
  list-style: none;
  left: 75%;
}
.module-privilege .privilege-ul .privilege-item {
  text-align: center;
  height: 45px;
  line-height: 45px;
  font-size: 14px;
}
.module-privilege .privilege-ul .privilege-item a {
  color: #666;
  display: block;
  width: 100%;
  height: 100%;
  cursor: pointer;
  text-decoration: none;
}
.module-privilege .privilege-ul .privilege-item.first-item {
  height: 80px;
  padding-top: 20px;
}
.module-privilege .privilege-ul .privilege-item.first-item .buy-button-base {
  margin-top: 14px;
}
.module-privilege .privilege-ul .privilege-item .title,
.module-privilege .privilege-ul .privilege-item .svip-type {
  font-size: 16px;
}
.module-privilege .privilege-ul .privilege-item .title .vip-type-icon,
.module-privilege .privilege-ul .privilege-item .svip-type .vip-type-icon {
  display: inline-block;
  *display: inline;
  *zoom: 1;
  vertical-align: middle;
  height: 30px;
}
.module-privilege .privilege-ul .privilege-item .title .vip-type-title,
.module-privilege .privilege-ul .privilege-item .svip-type .vip-type-title {
  height: 30px;
  line-height: 30px;
  display: inline-block;
  vertical-align: middle;
}
.module-privilege .privilege-ul .privilege-item .vip-type,
.module-privilege .privilege-ul .privilege-item .no-vip-type {
  font-size: 16px;
  margin-top: -4px;
}
.module-privilege .privilege-ul .privilege-item .vip-type .vip-type-icon,
.module-privilege .privilege-ul .privilege-item .no-vip-type .vip-type-icon {
  display: inline-block;
  *display: inline;
  *zoom: 1;
  vertical-align: middle;
  height: 30px;
}
.module-privilege .privilege-ul .privilege-item .vip-type .vip-type-title,
.module-privilege .privilege-ul .privilege-item .no-vip-type .vip-type-title {
  height: 30px;
  line-height: 30px;
  display: inline-block;
  vertical-align: middle;
}
.module-privilege .privilege-ul .privilege-item .icon {
  display: inline-block;
  *display: inline;
  *zoom: 1;
  width: 20px;
  height: 20px;
}
.module-privilege .privilege-ul .privilege-item .icon.right {
  margin-top: 12px;
  background-image: url(./images/vip/right.png);
  background-repeat: no-repeat;
}
.module-privilege .privilege-ul .privilege-item .icon.error {
  margin-top: 15px;
  background-image: url(./images/vip/error.png);
  background-repeat: no-repeat;
}
.module-privilege .privilege-ul.recommend {
  background-image: url(./images/vip/recommend.png);
  background-position: -1px -1px;
  background-repeat: no-repeat;
}
.module-privilege .privilege-ul.active {
  border-color: #f2ddaf;
  background-color: #fffaeb;
  margin-top: -12px;
  width: 25%;
  left: 25%;
  z-index: 10;
}
.module-privilege .privilege-ul.active .privilege-item.first-item {
  height: 92px;
}
.module-privilege .privilege-ul.active .privilege-item.last-item {
  height: 49px;
}
.icon-size-middle {
  width: 30px;
  height: 30px;
}
.vip-icon,
.buy-btn-box {
  display: inline-block;
}
.vip-icon.svip-middle {
  background-image: url(./images/vip/level2.png);
  background-repeat: no-repeat;
}
.vip-icon.vip-middle {
  background-image: url(./images/vip/level1.png);
  background-repeat: no-repeat;
}
.vip-icon.no-vip-middle {
  background-image: url(./images/vip/level0.png);
  background-repeat: no-repeat;
}
.center-button-container-middle {
  width: 120px;
  height: 30px;
  font-size: 14px;
  line-height: 30px;
  text-align: center;
  border-radius: 15px;
}
.center-button-light-red {
  border: 1px solid rgba(228, 88, 88, 0.5);
  color: #e45858;
}
.center-button-light-red:hover {
  border: 1px solid #e45858;
}
.center-button-light-yellow {
  border: 1px solid #f9d681;
  background-color: #f9d681;
  color: #351d06;
}
.center-button-light-yellow:hover {
  background-color: #e7c062;
}
.center-button-base {
  cursor: pointer;
  display: inline-block;
  margin: 0 5px;
}
</style>
